import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from 'moment';
import dayjs from 'dayjs';
  
function Create() {
  const [changed, setChanged] = useState(false);
  const [newAsset, setNewAsset] = useState();
  var [assetTypes, setAssetTypes] = useState([]);
  const [assetTypesLoaded, setAssetTypesLoaded] = useState(false);
  var [companies, setCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  var [capacities, setCapacities] = useState([]);
  const [capacitiesLoaded, setCapacitiesLoaded] = useState(false);
  var [descriptions, setDescriptions] = useState([]);
  const [descriptionsLoaded, setDescriptionsLoaded] = useState(false);
  var [makes, setMakes] = useState([]);
  const [makesLoaded, setMakesLoaded] = useState(false);
  var [maxHeights, setMaxHeights] = useState([]);
  const [maxHeightsLoaded, setMaxHeightsLoaded] = useState(false);
  var [models, setModels] = useState([]);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  var [siteLocations, setSiteLocations] = useState([]);
  const [siteLocationsLoaded, setSiteLocationsLoaded] = useState(false);
  var [companyAcId, setCompanyAcId] = useState();
  var [newCatKey, setNewCatKey] = useState();

  const date = {
    now: dayjs().format('YYYY-MM-DD')
  };

  const marks = [
    {
      value: 0,
      label: 'No',
    },
    {
      value: 1,
      label: 'Yes',
    },
  ];

useEffect(()=> {
  console.log(changed);
  console.log('New Asset ',newAsset);
});

const [responseOpen, setResponseOpen] = React.useState(false);
const [message, setMessage] = useState('');

                               
useEffect(()=> {
  if (localStorage.getItem('canAddAsset') === '1' ) {
    
  } else {
    window.location.href='/'
  }
},[]);

const handleResponseClose = () => {
setResponseOpen(false);
window.location.href='/?assetNo='+[newAsset.assetId]
};

async function createAsset(e) {
  e.preventDefault();
  const url = baseUrl;
  await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(newAsset),
  })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setResponseOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setResponseOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setResponseOpen(true);
        });
}

const loadAssetTypes = async () => {
    const url = baseUrl + 'asset/typeAc/';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          setAssetTypes(json);
          console.log('Types Loaded ', assetTypes)
      })
      .catch((err) => {
        setAssetTypesLoaded(false);
         console.log(err.message);
      });
    }

const loadCompanies = async () => {
    const url = baseUrl + 'company/listAc/';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          console.log('companies ',json)
          setCompanies(json);
          setCompanyAcId(`${Math.floor((Math.random() * 1000))}-min`);
          console.log('companies named ',companies)
      })
      .catch((err) => {
        setCompaniesLoaded(false);
         console.log(err.message);
      });
    }

const loadCapacities = async () => {
      const url = baseUrl + 'asset/capacityAc/';
        await fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setCapacities(json);
        })
        .catch((err) => {
          setCapacitiesLoaded(false);
           console.log(err.message);
        });
      }

const loadDescriptions = async () => {
        const url = baseUrl + 'asset/descriptionAc/';
          await fetch(url, {
            method: 'GET',
            headers: {
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken')
            }})
            .then((res) => {
              return res.json();
          }).then((json) => {
              console.log(json)
              setDescriptions(json);
          })
          .catch((err) => {
            setDescriptionsLoaded(false);
             console.log(err.message);
          });
        }

const loadMakes = async () => {
          const url = baseUrl + 'asset/makeAc/';
            await fetch(url, {
              method: 'GET',
              headers: {
                  'username': localStorage.getItem('username'),
                  'bToken': localStorage.getItem('bToken')
              }})
              .then((res) => {
                return res.json();
            }).then((json) => {
                console.log(json)
                setMakes(json);
            })
            .catch((err) => {
              setMakesLoaded(false);
               console.log(err.message);
            });
          }

const loadMaxHeights = async () => {
            const url = baseUrl + 'asset/maxHeightAc/';
              await fetch(url, {
                method: 'GET',
                headers: {
                    'username': localStorage.getItem('username'),
                    'bToken': localStorage.getItem('bToken')
                }})
                .then((res) => {
                  return res.json();
              }).then((json) => {
                  console.log(json)
                  setMaxHeights(json);
              })
              .catch((err) => {
                setMaxHeightsLoaded(false);
                 console.log(err.message);
              });
            }

const loadSiteLocations = async () => {
              const url = baseUrl + 'asset/siteLocationAc/';
                await fetch(url, {
                  method: 'GET',
                  headers: {
                      'username': localStorage.getItem('username'),
                      'bToken': localStorage.getItem('bToken')
                  }})
                  .then((res) => {
                    return res.json();
                }).then((json) => {
                    console.log(json)
                    setSiteLocations(json);
                })
                .catch((err) => {
                  setSiteLocationsLoaded(false);
                   console.log(err.message);
                });
              }

const loadModels = async () => {
              const url = baseUrl + 'asset/modelAc/';
                await fetch(url, {
                  method: 'GET',
                  headers: {
                      'username': localStorage.getItem('username'),
                      'bToken': localStorage.getItem('bToken')
                  }})
                  .then((res) => {
                    return res.json();
                }).then((json) => {
                    console.log(json)
                    setModels(json);
                })
                .catch((err) => {
                  setModelsLoaded(false);
                   console.log(err.message);
                });
              }
          
useEffect(() => {
  if (!assetTypesLoaded ) {
    setAssetTypesLoaded(true)
    loadAssetTypes()
  }
  if (!companiesLoaded ) {
    setCompaniesLoaded(true)
    loadCompanies()
  }
  if (!capacitiesLoaded ) {
    setCapacitiesLoaded(true)
    loadCapacities()
  }
  if (!descriptionsLoaded ) {
    setDescriptionsLoaded(true)
    loadDescriptions()
  }
  if (!makesLoaded ) {
    setMakesLoaded(true)
    loadMakes()
  }
  if (!maxHeightsLoaded ) {
    setMaxHeightsLoaded(true)
    loadMaxHeights()
  }
  if (!siteLocationsLoaded ) {
    setSiteLocationsLoaded(true)
    loadSiteLocations()
  }
  if (!modelsLoaded ) {
    setModelsLoaded(true)
    loadModels()
  }
})

return (
    <div>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            [{message}]
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <div className="createAsset">
    <p style={{fontWeight: "bolder", fontSize: "20px", color: "darkorange"}}>Create New Asset</p>
    <p style={{fontSize: "15px"}}> Please fill out all below fields to</p>
    <p style={{fontSize: "15px"}}>create a new Asset in the System.</p>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="catNo" 
          required
          label="Catalogue Number" 
          variant="outlined" 
          type="text" 
          onChange={(e) => {
            setChanged(true);
            setNewAsset({
               ...newAsset,
            catNo: e.target.value,
            newCat: 1,
            });
            setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
     </Box>
        <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="assetId" 
          required
          label="Asset Number" 
          variant="outlined" 
          type="text" 
          onChange={(e) => {
            setChanged(true);
            setNewAsset({
               ...newAsset,
            assetId: e.target.value,
            newCat: 1,
            });
            setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id={companyAcId}
  options={companies}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    customer: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Customer" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id="typeAc"
  options={assetTypes}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    type: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Type" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id="siteLocationAc"
  options={siteLocations}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    siteLocation: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Site Location" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={descriptions}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    item: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Item" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={makes}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    manufacturer: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Manufacturer" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={models}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    model: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Model" />}
    />
  </FormControl>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="serialNo" 
          label="Serial Number" 
          variant="outlined" 
          type="text" 
          onChange={(e) => {
            setChanged(true);
            setNewAsset({
               ...newAsset,
            serialNo: e.target.value,
            newCat: 1,
            });
            setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={maxHeights}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    maxHeight: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Max Height" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id="typeAc"
  options={capacities}
  onChange={(e) => {
    setChanged(true);
    setNewAsset({
       ...newAsset,
    capacity: e.target.innerHTML,
    newCat: 1,
    });
    setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Capacity" />}
    />
  </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
     <MobileDatePicker 
        slotProps={{ textField: { fullWidth: true } }}
        sx={{ mt: 1, mb: 1 }}
        margin="dense"
        id="buildDate" 
        label="Build Date"
        variant="outlined" 
        format="DD-MM-YYYY"
        defaultValue={dayjs(date.now)}
        onChange={(e) => {
          setChanged(true);
          setNewAsset({
             ...newAsset,
          buildDate: (moment(e).format('YYYY-MM-DD') === 'Invalid date' ) ? '-' : moment(e).format('YYYY-MM-DD'),
          newCat: 1,
          });
          setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
        }}
      />
     <MobileDatePicker 
        slotProps={{ textField: { fullWidth: true } }}
        sx={{ mt: 1, mb: 1 }}
        margin="dense"
        id="expiringCalibrationDate" 
        label="Calibration Expiry Date"
        variant="outlined" 
        format="DD-MM-YYYY"
        defaultValue={dayjs(date.now)}
        onChange={(e) => {
          setChanged(true);
          setNewAsset({
             ...newAsset,
          expiringCalibrationDate: (moment(e).format('YYYY-MM-DD') === 'Invalid date' ) ? '-' : moment(e).format('YYYY-MM-DD'),
          modifiedCat: 1,
          });
          setNewCatKey(`${Math.floor((Math.random() * 1000))}-min`);
        }}
      />
     </LocalizationProvider>
     <Box sx={{ maxWidth: '85%' , alignContent: 'right'}}>
     <Typography left>New Asset Setup</Typography>
        <Slider 
          size="small"
          step={1}
          min={0}
          max={1}
          key={newCatKey}
          defaultValue={newAsset ? newAsset.newCat : 0}
          marks={marks}
          valueLabelDisplay="off"
          onChange={(e) => {
            setChanged(true);
            setNewAsset({
               ...newAsset,
               newCat: e.target.value,
               });
            }}
        />
</Box>
     
{changed && newAsset.assetId && newAsset.catNo ? ( <form
                        className="w-full max-w-sm"
                        id="asset"
                        onClick={createAsset}
                    >
                        <div className="mb-2">
                            <Button variant="contained"
                                form="asset"
                            >
                                Create Cat No.
                            </Button>
                        </div>
                        </form>
                        
                    ) : null
                  }    
                  <div className="mb-2">
                  <Button variant="contained"
                      onClick={(e) => {
                          window.location.href='/'
                      }}
                  >
                      Cancel Cat No. Create
                  </Button>
              </div>
<br></br>
        </div>
  </div>
)};
       
export default Create