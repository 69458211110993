import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from 'moment';
  
function Edit({foundAsset}) {
  const [changed, setChanged] = useState(false);
  const [assetEdit] = useState(foundAsset);
  const [modAsset, setModAsset] = useState(foundAsset);
  var [assetTypes, setAssetTypes] = useState([]);
  const [assetTypesLoaded, setAssetTypesLoaded] = useState(false);
  var [companies, setCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  var [capacities, setCapacities] = useState([]);
  const [capacitiesLoaded, setCapacitiesLoaded] = useState(false);
  var [descriptions, setDescriptions] = useState([]);
  const [descriptionsLoaded, setDescriptionsLoaded] = useState(false);
  var [makes, setMakes] = useState([]);
  const [makesLoaded, setMakesLoaded] = useState(false);
  var [maxHeights, setMaxHeights] = useState([]);
  const [maxHeightsLoaded, setMaxHeightsLoaded] = useState(false);
  var [models, setModels] = useState([]);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  var [siteLocations, setSiteLocations] = useState([]);
  const [siteLocationsLoaded, setSiteLocationsLoaded] = useState(false);
  var [companyAcId, setCompanyAcId] = useState();
  var [modifiedCatKey, setModifiedCatKey] = useState();


  const marks = [
    {
      value: 0,
      label: 'No',
    },
    {
      value: 1,
      label: 'Yes',
    },
  ];

useEffect(()=> {
  console.log(changed);
  console.log('Edit Asset ',assetEdit);
  console.log('Mod Asset ',modAsset);
});

const [open, setOpen] = React.useState(false);
const [responseOpen, setResponseOpen] = React.useState(false);
const [message, setMessage] = useState('');


const handleResponseClose = () => {
setResponseOpen(false);
window.location.href='/?assetNo='+[modAsset.assetId]
};


    const handleClickOpen = () => {
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

async function updateAsset(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [assetEdit.id];
  await fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken'),
          'editType': 'Update'
      },
      body: JSON.stringify(modAsset),
  })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setResponseOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setResponseOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setResponseOpen(true);
        });
}

async function deleteAsset(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [assetEdit.id];
  await fetch(url, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
  })
      .then((data) => {
        window.location.href='/'
      });
}

const loadAssetTypes = async () => {
    const url = baseUrl + 'asset/typeAc/';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          setAssetTypes(json);
          console.log('Types Loaded ', assetTypes)
      })
      .catch((err) => {
        setAssetTypesLoaded(false);
         console.log(err.message);
      });
    }

const loadCompanies = async () => {
    const url = baseUrl + 'company/listAc/';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          console.log('companies ',json)
          setCompanies(json);
          setCompanyAcId(`${Math.floor((Math.random() * 1000))}-min`);
          console.log('companies named ',companies)
      })
      .catch((err) => {
        setCompaniesLoaded(false);
         console.log(err.message);
      });
    }

const loadCapacities = async () => {
      const url = baseUrl + 'asset/capacityAc/';
        await fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setCapacities(json);
        })
        .catch((err) => {
          setCapacitiesLoaded(false);
           console.log(err.message);
        });
      }

const loadDescriptions = async () => {
        const url = baseUrl + 'asset/descriptionAc/';
          await fetch(url, {
            method: 'GET',
            headers: {
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken')
            }})
            .then((res) => {
              return res.json();
          }).then((json) => {
              console.log(json)
              setDescriptions(json);
          })
          .catch((err) => {
            setDescriptionsLoaded(false);
             console.log(err.message);
          });
        }

const loadMakes = async () => {
          const url = baseUrl + 'asset/makeAc/';
            await fetch(url, {
              method: 'GET',
              headers: {
                  'username': localStorage.getItem('username'),
                  'bToken': localStorage.getItem('bToken')
              }})
              .then((res) => {
                return res.json();
            }).then((json) => {
                console.log(json)
                setMakes(json);
            })
            .catch((err) => {
              setMakesLoaded(false);
               console.log(err.message);
            });
          }

const loadMaxHeights = async () => {
            const url = baseUrl + 'asset/maxHeightAc/';
              await fetch(url, {
                method: 'GET',
                headers: {
                    'username': localStorage.getItem('username'),
                    'bToken': localStorage.getItem('bToken')
                }})
                .then((res) => {
                  return res.json();
              }).then((json) => {
                  console.log(json)
                  setMaxHeights(json);
              })
              .catch((err) => {
                setMaxHeightsLoaded(false);
                 console.log(err.message);
              });
            }

const loadSiteLocations = async () => {
              const url = baseUrl + 'asset/siteLocationAc/';
                await fetch(url, {
                  method: 'GET',
                  headers: {
                      'username': localStorage.getItem('username'),
                      'bToken': localStorage.getItem('bToken')
                  }})
                  .then((res) => {
                    return res.json();
                }).then((json) => {
                    console.log(json)
                    setSiteLocations(json);
                })
                .catch((err) => {
                  setSiteLocationsLoaded(false);
                   console.log(err.message);
                });
              }

const loadModels = async () => {
              const url = baseUrl + 'asset/modelAc/';
                await fetch(url, {
                  method: 'GET',
                  headers: {
                      'username': localStorage.getItem('username'),
                      'bToken': localStorage.getItem('bToken')
                  }})
                  .then((res) => {
                    return res.json();
                }).then((json) => {
                    console.log(json)
                    setModels(json);
                })
                .catch((err) => {
                  setModelsLoaded(false);
                   console.log(err.message);
                });
              }
          
useEffect(() => {
  if (!assetTypesLoaded ) {
    setAssetTypesLoaded(true)
    loadAssetTypes()
  }
  if (!companiesLoaded ) {
    setCompaniesLoaded(true)
    loadCompanies()
  }
  if (!capacitiesLoaded ) {
    setCapacitiesLoaded(true)
    loadCapacities()
  }
  if (!descriptionsLoaded ) {
    setDescriptionsLoaded(true)
    loadDescriptions()
  }
  if (!makesLoaded ) {
    setMakesLoaded(true)
    loadMakes()
  }
  if (!maxHeightsLoaded ) {
    setMaxHeightsLoaded(true)
    loadMaxHeights()
  }
  if (!siteLocationsLoaded ) {
    setSiteLocationsLoaded(true)
    loadSiteLocations()
  }
  if (!modelsLoaded ) {
    setModelsLoaded(true)
    loadModels()
  }
})

return (
    <div>
      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Are you sure you want to delete the Asset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAsset}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            [{message}]
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

        <div>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="catNo" 
          label="Catalogue Number" 
          variant="outlined" 
          type="text" 
          InputProps={{
            readOnly: true,
          }}
          value={modAsset.catNo} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            catNo: e.target.value,
            modifiedCat: 1,
            });
            setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
     </Box>
        <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="assetId" 
          label="Asset Number" 
          variant="outlined" 
          type="text" 
          value={modAsset.assetId} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            assetId: e.target.value,
            modifiedCat: 1,
            });
            setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id={companyAcId}
  options={companies}
  defaultValue={modAsset.customer}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    customer: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Customer" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id="typeAc"
  options={assetTypes}
  defaultValue={modAsset.type}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    type: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Type" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id="siteLocationAc"
  options={siteLocations}
  defaultValue={modAsset.siteLocation}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    siteLocation: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Site Location" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={descriptions}
  defaultValue={modAsset.item}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    item: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Item" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={makes}
  defaultValue={modAsset.manufacturer}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    manufacturer: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Manufacturer" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={models}
  defaultValue={modAsset.model}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    model: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Model" />}
    />
  </FormControl>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="serialNo" 
          label="Serial Number" 
          variant="outlined" 
          type="text" 
          value={modAsset.serialNo} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            serialNo: e.target.value,
            modifiedCat: 1,
            });
            setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  options={maxHeights}
  defaultValue={modAsset.maxHeight}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    maxHeight: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Max Height" />}
    />
  </FormControl>
     <FormControl
          margin="dense"
     fullWidth>
     <Autocomplete
     required
  disablePortal
  disableClearable
  id="typeAc"
  options={capacities}
  defaultValue={modAsset.capacity}
  onChange={(e) => {
    setChanged(true);
    setModAsset({
       ...modAsset,
    capacity: e.target.innerHTML,
    modifiedCat: 1,
    });
    setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
    }}
    renderInput={(params) => <TextField {...params} label="Capacity" />}
    />
  </FormControl>
      <LocalizationProvider dateAdapter={AdapterMoment}>
     <MobileDatePicker 
        slotProps={{ textField: { fullWidth: true } }}
        sx={{ mt: 1, mb: 1 }}
        margin="dense"
        id="buildDate" 
        label="Build Date"
        variant="outlined" 
        format="DD-MM-YYYY"
        defaultValue={moment(modAsset.buildDate)}
        onChange={(e) => {
          console.log('here',e)
          setChanged(true);
          setModAsset({
             ...modAsset,
          buildDate: (moment(e).format('YYYY-MM-DD') === 'Invalid date' ) ? '-' : moment(e).format('YYYY-MM-DD'),
          modifiedCat: 1,
          });
          setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
        }}
      />
     <MobileDatePicker 
        slotProps={{ textField: { fullWidth: true } }}
        sx={{ mt: 1, mb: 1 }}
        margin="dense"
        id="expiringCalibrationDate" 
        label="Calibration Expiry Date"
        variant="outlined" 
        format="DD-MM-YYYY"
        defaultValue={moment(modAsset.expiringCalibrationDate)}
        onChange={(e) => {
          setChanged(true);
          setModAsset({
             ...modAsset,
          expiringCalibrationDate: (moment(e).format('YYYY-MM-DD') === 'Invalid date' ) ? '-' : moment(e).format('YYYY-MM-DD'),
          modifiedCat: 1,
          });
          setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
        }}
      />
     </LocalizationProvider>
     <Box sx={{ maxWidth: '85%' , alignContent: 'right'}}>
     <Typography left>New Asset Setup</Typography>
        <Slider 
          size="small"
          defaultValue={modAsset.newCat}
          step={1}
          min={0}
          max={1}
          marks={marks}
          valueLabelDisplay="off"
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
               newCat: e.target.value,
               modifiedCat: 1,
               });
               setModifiedCatKey(`${Math.floor((Math.random() * 1000))}-min`);
            }}
        />
</Box>
     <Box sx={{ maxWidth: '85%' , alignContent: 'right'}}>
     <Typography left>Asset Changed</Typography>
        <Slider 
          size="small"
          key={modifiedCatKey}
          defaultValue={modAsset.modifiedCat}
          step={1}
          min={0}
          max={1}
          marks={marks}
          valueLabelDisplay="off"
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
               modifiedCat: e.target.value,
            });
            }}
        />
</Box>
     
 <form
                        className="w-full max-w-sm"
                        id="asset"
                        onClick={updateAsset}
                    >
                     <br></br>
                        <div className="mb-2">
                            <Button variant="contained"
                              disabled={!changed}
                                form="asset"
                            >
                                Save Changes
                            </Button>
                            &nbsp;&nbsp;&nbsp; 
                            <Button variant="contained"
                                onClick={(e) => {
                                    window.location.href='/?assetNo='+[modAsset.assetId]
                                }}
                            >
                                Cancel Editing
                            </Button>
                            &nbsp;&nbsp;&nbsp;  
                            { localStorage.getItem('canDeleteAsset') === '1' ? (
                            <Button variant="contained"
                                form="assetDelete"
                                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleClickOpen}
                            >
                                Delete Asset
                            </Button>
                            ): null}
                            </div>
                        </form>
        </div>
  </div>
)};
                              
export default Edit