import React, { useState } from "react";
import  { useNavigate, Navigate } from 'react-router-dom'
import { FiUpload } from "react-icons/fi";
import { baseUrl } from "../../shared";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function TypeBulk() {

  const [file, setFile] = useState({
    selectedFile: null
  });

  const [dragActive, setDragActive] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleClose = () => {
    <Navigate to={'/admin/'} />
    {navigate(0)}
  };

  const checkFileType = (e, eventType) => {
    let extension;

    if (eventType === "drop") {
      extension = e.dataTransfer.files[0].name.match(/\.([^.]+)$/)[1];
    } else {
      extension = e.target.value.match(/\.([^.]+)$/)[1];
    }

    switch (extension) {
      case "csv":
        eventType !== "drop"
          ? setFile({ selectedFile: e.target.files[0] })
          : setFile({ selectedFile: e.dataTransfer.files[0] });
        setMsg("");
        break;
      default:
        setFile({ selectedFile: null });
        setMsg(`.${extension} format is not supported.`);
    }
  };

  const checkSize = (e, eventType) => {
    let size;
    if (eventType === "drop") {
      // console.log("size", e.dataTransfer.files[0]);
      size = e.dataTransfer.files[0].size / 8;
    } else {
      // console.log("size", e.target.files[0].size);
      size = e.target.files[0].size / 8;
    }
    // console.log("converted size", size);

    if (size <= 2048000) {
      checkFileType(e, eventType);
    } else {
      setMsg("Size should be less than 2MB");
    }
  };

  const chooseFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      checkSize(e);
      // checkFileType(e);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkSize(e, "drop");
      // checkFileType(e, "drop");
    }
  };

  async function uploadAttachment(e) {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
        "myFile",
        file.selectedFile
    );

    // Details of the uploaded file
    console.log('CSV Name: '+file.selectedFile.name);
    const url = baseUrl + "upload/descriptions";
    await fetch(url, {
        method: 'POST',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        },
         body: formData,
    })
    .then((response) => response.json())
      .then((data) => {
        console.log(data[0])
        if( data[0].status === 200) {
        setMessage(data[0].message);
        setOpen(true);
        } else if (data[0].status === 401) {
          setMessage(data[0].message);
          setOpen(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setOpen(true);
      });
  }

  return (
    <div className="FirstTab">
    <React.Fragment>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
      <DialogTitle>
              Description Bulk Upload
      </DialogTitle>
      <DialogContent>
          <DialogContentText>
              {message}
          </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
      </Dialog>
</React.Fragment>
      <form
        className="uploadBox"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onSubmit={(e) => e.preventDefault()}
      >
        {file.selectedFile !== null ? (
          <p className="filename">{file.selectedFile.name}</p>
        ) : msg !== "" ? (
          msg
        ) : (
          <FiUpload className="upload-icon" />
        )}

        <div>
          <div className="drag">
            Drop your Bulk CSV here or{" "}
            <div className="browse">
              <label
                htmlFor="img"
                className="file-label"
                onClick={() => document.getElementById("getFile").click()}
              >
                Browse
                <input
                  type="file"
                  data-max-size="2048"
                  id="getFile"
                  className="fileIcon"
                  onChange={chooseFile}
                />
              </label>
            </div>
          </div>
        </div>

        <p className="info">Supported files: CSV</p>
        <p className="info">Please Ensure CSV Contains Header Row</p>
        <p className="info">and ONLY Description Column</p>
        <br></br>
                        <div className="mb-2">
                            <Button variant="contained"
                            onClick={uploadAttachment}
                            >
                                Upload Bulk CSV
                            </Button>
                        </div>
      </form>
    </div>
  );
}