import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from 'moment';
import dayjs from 'dayjs';
  
function Inspect({foundAsset}) {
  const [changed, setChanged] = useState(false);
  const [assetEdit] = useState(foundAsset);
  const [modAsset, setModAsset] = useState(foundAsset);
  var [onLoadDate, setOnLoadDate] = useState(false);
  const [responseOpen, setResponseOpen] = React.useState(false);
  const [message, setMessage] = useState('');

  const date = {
    now: dayjs().format('YYYY-MM-DD'),
    future: dayjs().add(3, 'month').format('YYYY-MM-DD')
  };

  const marks = [
    {
      value: 0,
      label: 'No',
    },
    {
      value: 1,
      label: 'Yes',
    },
  ];

  const handleResponseClose = () => {
  setResponseOpen(false);
  window.location.href='/?assetNo='+[modAsset.assetId]
  };

useEffect(()=> {
  console.log(changed);
  console.log('Pass Asset ',assetEdit);
  console.log('Mod Asset ',modAsset);
  if (onLoadDate === false) {
    onLoad()
  }{
    setOnLoadDate(true)
  }
});

function onLoad(){
  setModAsset({
     ...modAsset,
     lastInspectionDate: date.now,
     nextInspectionDate: date.future,
  });
  setOnLoadDate(true)
  }


async function updateAsset(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [assetEdit.id];
  await fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken'),
          'editType': 'Inspect'
      },
      body: JSON.stringify(modAsset),
  })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if( data.status === 200) {
        setMessage(data.message);
        setResponseOpen(true);
        } else if (data.status === 401) {
          setMessage(data.message);
          setResponseOpen(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setResponseOpen(true);
      });
}

return (
    <div>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            [{message}]
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
        <div>
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
        <TextField 
        fullWidth
          margin="dense"
          id="catNo" 
          label="Category Number" 
          variant="outlined" 
          type="text" 
          InputProps={{
            readOnly: true,
          }}
          value={modAsset.catNo} 
          onChange={(e) => {
             setChanged(true);
             setModAsset({
                ...modAsset,
                catNo: e.target.value,
             });
             }}
        />
        </Box>
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
        <TextField 
        fullWidth
          margin="dense"
          id="assetId" 
          label="Asset Number" 
          variant="outlined" 
          type="text" 
          InputProps={{
            readOnly: true,
          }}
          value={modAsset.assetId} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            assetId: e.target.value,
            });
            }}
        />
        </Box>
    <Box
          margin="dense">
       <FormControl fullWidth>
  <InputLabel>Pass/Fail</InputLabel>
  <Select
    required
    label="Status"
    onChange={(e) => {
      setChanged(true);
      setModAsset({
         ...modAsset,
         inspectionStatus: e.target.value,
         inspectionReason: '',
      });
      }}>
    <MenuItem value="Pass">Pass</MenuItem>
    <MenuItem value="Fail">Fail</MenuItem>
  </Select>
</FormControl>
</Box>
<LocalizationProvider dateAdapter={AdapterDayjs}>
     <MobileDatePicker 
        slotProps={{ textField: { fullWidth: true } }}
        sx={{ mt: 1, mb: 1 }}
        margin="dense"
        id="inspectionDate" 
        label="Inspection Date"
        variant="outlined" 
        format="DD-MM-YYYY"
        defaultValue={dayjs(date.now)}
        onChange={(e) => {
          setChanged(true);
          setModAsset({
             ...modAsset,
             lastInspectionDate: (e === null ) ? '':moment(e.$d).format('YYYY-MM-DD'),
          });
        }}
      />
      <MobileDatePicker 
         slotProps={{ textField: { fullWidth: true } }}
         sx={{ mt: 1, mb: 1 }}
         margin="dense"
         id="nextInspectionDate" 
         label="Next Inspection Due"
         variant="outlined" 
         format="DD-MM-YYYY"
         defaultValue={dayjs(date.future)}
         onChange={(e) => {
           setChanged(true);
           setModAsset({
              ...modAsset,
              nextInspectionDate: (e === null ) ? '':moment(e.$d).format('YYYY-MM-DD'),
           });
         }}
       />
      </LocalizationProvider>
       <Box
          margin="dense">
          <FormControl fullWidth>
       <TextField
          id="inspectionReason"
          label="Comments"
          multiline
          rows={4}
          onChange={(e) => {
            setModAsset({
               ...modAsset,
               inspectionReason: e.target.value,
            });
            }}
        />
        </FormControl>
     </Box>
     <Box sx={{ maxWidth: '85%' , alignContent: 'right'}}>
     <Typography left>First Time Inspecting Asset</Typography>
        <Slider 
          size="small"
          defaultValue={0}
          step={1}
          min={0}
          max={1}
          marks={marks}
          valueLabelDisplay="off"
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
               firstTimeInspected: e.target.value,
            });
            }}
        />
</Box>
     <div className="mb-2">
                            <Button variant="contained"
                                onClick={(e) => {
                                    window.location.href='/?assetNo='+[modAsset.assetId]
                                }}
                            >
                                Cancel Inspection
                            </Button>
                        </div>
<form
                        className="w-full max-w-sm"
                        id="asset"
                    >
                        <div className="mb-2">
                            <Button variant="contained"
                            disabled={!changed}
                                form="asset"
                                onClick={updateAsset}
                            >
                                Save Inspection Results
                            </Button>
                        </div>
                        </form>
        </div>
  </div>
)};
                              
export default Inspect