import React, { useEffect, useState } from "react";
import '../pages/App.css';
import Button from '@mui/material/Button';
import CapacityBulk from './bulkFunctions/capacityBulk';
import CompanyBulk from "./bulkFunctions/companyBulk";
import DescriptionBulk from "./bulkFunctions/decriptionBulk";
import MakeBulk from "./bulkFunctions/makeBulk";
import MaxHeightBulk from "./bulkFunctions/maxHeightBulk";
import ModelsBulk from "./bulkFunctions/modelBulk";
import SiteLocationsBulk from "./bulkFunctions/siteLocationBulk";
import TypeBulk from "./bulkFunctions/typeBulk";
import { AlignHorizontalRight } from "@mui/icons-material";

export function BulkUploads() {

    let [loginStatus, setLoginStatus] = useState(false);
    let [bulkCompanyList, setBulkCompanyList] = useState(false);
    let [bulkCapacityList, setBulkCapacityList] = useState(false);
    let [bulkDescriptionList, setBulkDescriptionList] = useState(false);
    let [bulkMakeList, setBulkMakeList] = useState(false);
    let [bulkMaxHeightList, setBulkMaxHeightList] = useState(false);
    let [bulkModelList, setBulkModelList] = useState(false);
    let [bulkSiteLocationList, setBulkSiteLocationList] = useState(false);
    let [bulkTypeList, setBulkTypeList] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
      };

    function clearSelection() {
      setBulkCompanyList(false);
      setBulkCapacityList(false);
      setBulkDescriptionList(false);
      setBulkMakeList(false);
      setBulkMaxHeightList(false);
      setBulkModelList(false);
      setBulkSiteLocationList(false);
      setBulkTypeList(false);
    }
  
    const bulkCompanieslist = () => {
      setBulkCompanyList(true);
      console.log('bulkCompanyList ',bulkCompanyList)
    }; 
  
    const bulkCapacitiesList = () => {
      setBulkCapacityList(true);
      console.log('bulkCapacityList ',bulkCapacityList)
    }; 
  
    const bulkDescriptionsList = () => {
      setBulkDescriptionList(true);
      console.log('bulkDescriptionList ',bulkDescriptionList)
    }; 
  
    const bulkMakesList = () => {
      setBulkMakeList(true);
      console.log('bulkMakeList ',bulkMakeList)
    }; 
  
    const bulkMaxHeightsList = () => {
      setBulkMaxHeightList(true);
      console.log('bulkMaxHeightList ',bulkMaxHeightList)
    }; 
  
    const bulkModelsList = () => {
      setBulkModelList(true);
      console.log('bulkModelList ',bulkModelList)
    }; 
  
    const bulkSiteLocationsList = () => {
      setBulkSiteLocationList(true);
      console.log('bulkSiteLocationList ',bulkSiteLocationList)
    }; 
  
    const bulkTypesList = () => {
      setBulkTypeList(true);
      console.log('bulkTypeList ',bulkTypeList)
    }; 

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]); 

    return (
        <div className="admin">
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkCompanieslist}>Bulk Add Companies</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkCapacitiesList}>Bulk Add Capacities</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkDescriptionsList}>Bulk Add Descriptions</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkMakesList}>Bulk Add Makes</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkMaxHeightsList}>Bulk Add Max Heights</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkModelsList}>Bulk Add Models</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkSiteLocationsList}>Bulk Add Site Locations</Button>
          </>
        ) : null}
          { loginStatus && !bulkCompanyList && !bulkCapacityList && !bulkDescriptionList && !bulkMakeList && !bulkMaxHeightList && !bulkModelList && !bulkSiteLocationList && !bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
          <>
          <br />
          <Button variant="contained" onClick={bulkTypesList}>Bulk Add Types</Button>
          </>
        ) : null}
        { loginStatus && bulkCompanyList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<CompanyBulk />}
            </>
        ) : null} 
        { loginStatus && bulkCapacityList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<CapacityBulk />}
            </>
        ) : null} 
        { loginStatus && bulkDescriptionList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<DescriptionBulk />}
            </>
        ) : null} 
        { loginStatus && bulkMakeList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<MakeBulk />}
            </>
        ) : null} 
        { loginStatus && bulkMaxHeightList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<MaxHeightBulk />}
            </>
        ) : null} 
        { loginStatus && bulkModelList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<ModelsBulk />}
            </>
        ) : null} 
        { loginStatus && bulkSiteLocationList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<SiteLocationsBulk />}
            </>
        ) : null} 
        { loginStatus && bulkTypeList && localStorage.getItem('canBulkAdd') === '1' ? (
            <>
            <br />
            <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearSelection}>Return to Selection</Button>
            <br />
            {<TypeBulk />}
            </>
        ) : null} 
        <br />
        <br />
        </div>
    )
}

export default BulkUploads